import React, { useEffect, useLayoutEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

import Modal from "./Modal";
import TabsWithContent from "./TabsWithContent";
import RemoteSubmitButton from "../forms/RemoteSubmitButton";
import { connect, useSelector } from "react-redux";
import Spinner from "./Spinner";
import {
  cameraClearState,
  cameraCreate,
  cameraEdit,
  cameraList,
  cameraLoad,
  dashcamEdit,
} from "../store/Camera";
import CameraForm from "../forms/CameraForm";
import CameraPermissionsForm from "../forms/CameraPermissionsForm";
import { cameraToApiData } from "../utils/utils";
import { clearPPEs, loadPPEs } from "../store/PPEs";
import { clearWorkwear, loadWorkwear } from "../store/Workwear";

const VALIDATION_ERROR= 'Пожалуйста заполните поля'
const CAMERA_FETCH_ERROR = 'Ошибка запроса'

const validate = (values) => {
  // Проверяем поле "name"
  if (!values?.name) {
    return false;
  }
  // Проверяем поле "object"
  if (!values?.object?.label) {
    return false;
  }
  // Проверяем поле "protocol"
  if (!values?.protocol?.label) {
    return false;
  }
  // Проверяем поле "host"
  if (!values?.host) {
    return false;
  }
  // Пример дополнительной логики: если протокол rtsp, требуются креды
  // if (values?.protocol?.value === "rtsp://" && !values?.credentials) {
  //   return false;
  // }
  return true; 
};

const modCategoryDetViol = (ppes, camera) => {
  let modData = [];

  if (camera && Object.keys(camera).length !== 0) {
    modData = ppes.map((ppe) => {
      if (
        camera.violation_categories?.find((object) => object.name === ppe.id)
      ) {
        return {
          value: "viol",
          name: ppe.id,
          label: ppe.ru_name,
        };
      } else if (
        camera.detection_categories?.find((object) => object.name === ppe.id)
      ) {
        return {
          value: "detect",
          name: ppe.id,
          label: ppe.ru_name,
        };
      } else {
        return {
          value: "pass",
          name: ppe.id,
          label: ppe.ru_name,
        };
      }
    });
  } else {
    modData = ppes.map((ppe) => {
      return {
        value: "pass",
        name: ppe.id,
        label: ppe.ru_name,
      };
    });
  }
  return modData;
};

const valuesToApiForm = (modData, values) => {
  let detectionCategories = [];
  let violationCategories = [];
  const newModData = modData.map((item) => {
    let newVal;
    for (const object of Object.entries(values)) {
      if (object[0] === item.name) {
        newVal = object[1];
      }
    }
    if (newVal) {
      return {
        value: newVal,
        name: item.name,
        label: item.label,
      };
    } else {
      return {
        value: item.value,
        name: item.name,
        label: item.label,
      };
    }
  });

  for (const item of newModData) {
    if (item.value === "detect") {
      detectionCategories.push(item.name);
    } else if (item.value === "viol") {
      violationCategories.push(item.name);
    }
  }

  return [detectionCategories, violationCategories];
};

const CameraModal = ({
  onClose,
  onCancel,
  isPending,
  isError,
  objectsList,
  ppes,
  workwear,
  camera,
  actions,
  confirm_loading,
  cameraEditCreateError,
  isEditModal
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [error, setError] = useState('');
  const [userFormValues, setUserFormValues] = useState({
    name: ''
  })
  useLayoutEffect(() => {
    actions.loadCamerasData();
    actions.loadWorkwear();
    actions.loadPPEs();
  }, []);

  useEffect(() => {
    if (cameraEditCreateError) {
      setError(CAMERA_FETCH_ERROR)
    }
  }, [cameraEditCreateError])

  return (
    <Modal
      title={`Камера `}
      isOpen
      showEditButton={!isEditMode}
      closeButtonWithText={isEditMode}
      onClose={onClose}
      onEdit={() =>     setIsEditMode(true)}
      className="user-modal"
    >
      {isPending  ? (
        isError ? (
          <p>Ошибка при загрузке данных</p>
        ) : (
          <Spinner />
        )
      ) : (
        <>
          <TabsWithContent
            titles={["Данные", "Детекторы"]}
            tabs={[
              <>
                <CameraForm
                  handleCancel={onCancel}
                  onSubmit={(values) => null
                  }
                  isEditMode={isEditMode}
                  objectsList={objectsList}
                  ppes={ppes}
                  setUserFormValues={setUserFormValues}
                  isEditModal={isEditModal}
                />
              </>,
              <>
                <CameraPermissionsForm
                  handleCancel={onCancel}
                  onSubmit={(values) => {
                    setError('');

                    const { createCamera, updateCamera, updateDashcam } = actions;
                    const validationResult = isEditModal && userFormValues?.isDashcam ? Boolean(userFormValues?.number) : validate(userFormValues);
                    let [detectionCategories, violationCategories] =
                      valuesToApiForm(
                        modCategoryDetViol([...workwear, ...ppes], camera),
                        values
                      );
                      
                    if (!validationResult) {
                      setError(VALIDATION_ERROR);
                      return;
                    }

                    if (isEditModal && userFormValues?.isDashcam) {
                      updateDashcam({...userFormValues, violation_categories: violationCategories, detection_categories: detectionCategories}, onClose);

                      return
                    }

                    const payload = {...userFormValues, protocol: userFormValues.protocol.value, object: userFormValues.object.value, is_enabled: !!userFormValues?.is_enabled, credentials: userFormValues?.credentials ?? ''    }
                    if (userFormValues.protocol.value !== "rtsp://") {
                      payload.credentials = "";
                    }

                     payload.violation_categories = violationCategories;
                     payload.detection_categories = detectionCategories;
                    if (payload?.id) {
                        updateCamera(payload.id, payload, userFormValues,  () => onClose());
                    } else {
                        createCamera(payload,userFormValues, () => onClose());
                    }
                  }}
                  isEditMode={isEditMode}
                  objectsList={objectsList}
                  modPpes={modCategoryDetViol([...workwear, ...ppes], camera)}
                />
                <div className="camera_wrapper_buttons">
                  {error && (
                    <p className="error-text">{error}</p>
                  )}
                  <div className="submit-wrapper">
                    {isEditMode && (
                      <RemoteSubmitButton
                        disable={confirm_loading}
                        forms={["CameraForm", "CameraPermissionsForm"]}
                        className=""
                      >
                        Сохранить
                      </RemoteSubmitButton>
                    )}
                  </div>
                </div>
              </>,
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default connect(
  (state) => ({
    isPending:
      !state.camera.list ||
      !state.ppes.list ||
      !state.workwear.list ||
      state.object.isListPending,
    isError: state.ppes.isError || state.workwear.isError,
    cameraEditCreateError: state.camera.createEditError ?? false,
    ppes: state.ppes.list,
    workwear: state.workwear.list,
    camera: state.camera.current,
    confirm_loading: state.camera.confirm_loading
  }),
  (dispatch) => ({
    actions: {
      loadCamerasData: () => {
        dispatch(cameraList());
      },
      createCamera: (camera, userFormValues, onClose) => {
        const apiData = cameraToApiData(camera);
        dispatch(cameraCreate(camera.object, apiData, userFormValues, onClose));
      },
      updateCamera: (cameraId, camera, userFormValues,  onClose) => {
        const apiData = cameraToApiData(camera);
  
        dispatch(cameraEdit(camera.obj_id, cameraId, apiData, userFormValues, onClose));
      },
      updateDashcam: (camera, onClose) => {
        const data = { number: camera?.name, model: camera?.model, dashcam_type: camera?.dashcam_type, 
          manuf_dt: camera?.manuf_dt, power_unit_id: camera?.power_unit_info?.id,
          violation_categories: camera?.violation_categories, detection_categories: camera?.detection_categories }

        dispatch(dashcamEdit(camera?.id, data, onClose))
      },
      loadCamera: (camera) => {
        // dispatch(cameraClearState());
        dispatch(cameraLoad(camera.object, camera.id));
      },
      loadPPEs: () => {
        dispatch(clearPPEs());
        dispatch(loadPPEs());
      },
      loadWorkwear: () => {
        dispatch(clearWorkwear());
        dispatch(loadWorkwear());
      },
    },
  })
)(CameraModal);
