import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-regular-svg-icons';

import Modal from './Modal';
import TabsWithContent from './TabsWithContent';
import RemoteSubmitButton from '../forms/RemoteSubmitButton';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import {cameraClearState, cameraCreate, cameraEdit, cameraList, cameraLoad} from '../store/Camera';
import {videoRecorderClearState, videoRecorderCreate, videoRecorderEdit, videoRecorderList, videoRecorderLoad} from '../store/VideoRecorder';
import CameraForm from "../forms/CameraForm";
import CameraPermissionsForm from "../forms/CameraPermissionsForm";
import {cameraToApiData} from "../utils/utils";
import {clearPPEs, loadPPEs} from "../store/PPEs";
import {clearWorkwear, loadWorkwear} from "../store/Workwear";
import CameraFormWithTabs from "../forms/CameraFormWithTabs";
import VideoRecorderForm from '../forms/VideoRecorderForm';

const objToIdsArr = (items) => items.map(item => item.name);

const modCategoryDetViol = (ppes, camera) => {
    let modData = [];
  
    if (camera && Object.keys(camera).length !== 0) {
      modData = ppes.map((ppe) => {
        if (
          camera.violation_categories?.find((object) => object.name === ppe.id)
        ) {
          return {
            value: "viol",
            name: ppe.id,
            label: ppe.ru_name,
          };
        } else if (
          camera.detection_categories?.find((object) => object.name === ppe.id)
        ) {
          return {
            value: "detect",
            name: ppe.id,
            label: ppe.ru_name,
          };
        } else {
          return {
            value: "pass",
            name: ppe.id,
            label: ppe.ru_name,
          };
        }
      });
    } else {
      modData = ppes.map((ppe) => {
        return {
          value: "pass",
          name: ppe.id,
          label: ppe.ru_name,
        };
      });
    }
    return modData;
  };

  const valuesToApiForm = (modData, values) => {
    let detectionCategories = [];
    let violationCategories = [];
    const newModData = modData.map((item) => {
      let newVal;
      for (const object of Object.entries(values)) {
        if (object[0] === item.name) {
          newVal = object[1];
        }
      }
      if (newVal) {
        return {
          value: newVal,
          name: item.name,
          label: item.label,
        };
      } else {
        return {
          value: item.value,
          name: item.name,
          label: item.label,
        };
      }
    });
  
    for (const item of newModData) {
      if (item.value === "detect") {
        detectionCategories.push(item.name);
      } else if (item.value === "viol") {
        violationCategories.push(item.name);
      }
    }
  
    return [detectionCategories, violationCategories];
  };

class VideoRecorderModal extends React.Component {
    state = {
        isEditMode: false,
        dashcamData: {}
    };

    userFormValues = {};

    componentDidMount() {
        this.props.actions.loadCamerasData();
        this.props.actions.loadWorkwear();
        this.props.actions.loadPPEs();
    }

    onEditForm() {
        this.setState({isEditMode: true})
    };


    render() {
        const {onClose, onCancel, isPending, isError, objectsList, ppes, workwear, camera} = this.props;
        const {createCamera, updateCamera} = this.props.actions;
        this.userFormValues = camera;
        const modPpes = workwear && ppes ? [...workwear, ...ppes] : []

        const setDashcamData = (data) => {
            this.setState({dashcamData: data})
        }

        return (
            <Modal
                title={`Видеорегистратор `}
                isOpen
                showEditButton={!this.state.isEditMode}
                closeButtonWithText={true}
                onClose={onClose}
                onEdit={() => this.onEditForm()}
                className="user-modal"
            >
                {isPending
                    ?
                    isError ?
                        <p>Ошибка при загрузке данных</p>
                        :
                        <Spinner/>
                    :
                    <>
                        <TabsWithContent
                            titles={['Данные', "Детекторы"]}
                            tabs={[
                                <>
                                    <VideoRecorderForm
                                        handleCancel={onCancel}
                                        isEditMode={this.state.isEditMode}
                                        objectsList={objectsList}
                                        setDashcamData={setDashcamData}
                                    />
                                </>,
                                <>
                                    <CameraPermissionsForm
                                        handleCancel={onCancel}
                                        isEditMode={this.state.isEditMode}
                                        objectsList={objectsList}
                                        modPpes={modCategoryDetViol(modPpes, camera)}
                                        onSubmit={(values) => {
                                            let [detectionCategories, violationCategories] =
                                            valuesToApiForm(
                                              modCategoryDetViol([...workwear, ...ppes], camera),
                                              values
                                            );

                                            if (this.state.dashcamData.id) {
                                                const data = {...this.state.dashcamData, detection_categories: detectionCategories, violation_categories: violationCategories, power_unit_id: this.state.dashcamData?.power_unit_info.value}
                                                updateCamera(this.state.dashcamData.id, data);
                                            } else {
                                                const data = {number: this.state.dashcamData.number, model: '', dashcam_type: '', manuf_dt: 0, detection_categories: detectionCategories, violation_categories: violationCategories, power_unit_id: this.state.dashcamData?.power_unit_info.value}

                                                createCamera(data);
                                            }
                                            onCancel();
                                        }}
                                    />

                                    <div className="submit-wrapper">
                                        {this.state.isEditMode &&
                                        <RemoteSubmitButton
                                            forms={['CameraPermissionsForm']}
                                            className=""
                                        >
                                            Сохранить
                                        </RemoteSubmitButton>}
                                    </div>
                                </>
                            ]}
                        />
                    </>
                }
            </Modal>
        )
    }
}

export default connect(
    state => ({
        isPending: !state.videoRecorder.list || state.object.isListPending,
        isError: state.videoRecorder.isError || state.object.isError,
        videoRecorder: state.videoRecorder.current,
        ppes: state.ppes.list,
        workwear: state.workwear.list,
    }),
    dispatch => ({
        actions: {
            loadCamerasData: () => {
                dispatch(cameraList());
            },
            createCamera: (camera) => {
                // const apiData = cameraToApiData(camera);
                dispatch(videoRecorderCreate(camera));
            },
            loadCamera: (camera) => {
                // dispatch(cameraClearState());
                dispatch(videoRecorderLoad(camera.id));
            },
            updateCamera: (cameraId, camera) => {
                // const apiData = cameraToApiData(camera);
                dispatch(videoRecorderEdit(cameraId, camera));
            },
            loadPPEs: () => {
                dispatch(clearPPEs());
                dispatch(loadPPEs());
            },
            loadWorkwear: () => {
                dispatch(clearWorkwear());
                dispatch(loadWorkwear());
            },
        }
    })
)(VideoRecorderModal);
