import axios from 'axios';

const initialState = null;

const USERS = {
    NOTIFICATION_SETTINGS: 'NOTIFICATION_SETTINGS',
    SET_CONFIG: 'SET_CONFIG',
}

const userSettingsReducer = (state = initialState, action) => {
    switch(action.type) {
        case USERS.LOAD_USER_NOTIFICATION_SETTINGS:
            return action.payload;
        case USERS.SET_CONFIG:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
};

export const getConfig = () => dispatch => {
  return axios.get('/api/config/ui-config/')
    .then((res) => {
      dispatch({
        type: USERS.SET_CONFIG,
        payload: { config: res.data }
      })
    })
}

export default userSettingsReducer;
