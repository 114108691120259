import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup} from '../inputs';
import {splitCameraUri, isBlock, rtspCredentials, omitRooms} from "../utils/utils";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";
import { parseFileName } from "../components/WorkwearModal";
import close from "./FileInput/close.png";
import Attach from "./FileInput/Attach";

const required = value => value ? undefined : 'Обязательное поле';

const ReduxFormProtocolSelect = ({input}) => {
    const isMulti = false;
    const options = protocolSelectOptions(['rtsp://', 'file:///', 'https://', 'http://']);
    const placeholder = 'Выберите протокол';
    return ReduxFormSelectValid(isMulti, options, placeholder)({input});
};

const protocolSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry,
            label: entry
        }
    })
};

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.name ? `${entry.name}` : entry.ru_name
        }
    })
};

const ppeSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.name ? entry.name : entry.ru_name
        }
    })
};

const protocolSelectActiveOptions = (protocol) => {
    return {
        value: protocol,
        label: protocol
    }
};

const objectsSelectActiveOptions = (objects, obj_id) => objects.map(object => {
    const activeObj = objects.find(item => item.id === obj_id);
    return {
        value: activeObj.id,
        label: `${activeObj.name}`
    }
});

const AttachmentForm = ({ attachmentData, removeAttached }) => {
    const [attachments, setAttachments] = useState([]);
    const [errors, setErrors] = useState(null);
  
    useEffect(() => {
      if(attachmentData.file_uri) {
        setAttachments([{ name: parseFileName(attachmentData.file_uri) }]);
      }
    }, [attachmentData])
  
    const remove = () => {
      setAttachments([]);
      removeAttached('');
    }
  
    return (
      <>
        {
          attachments.length !== 0 &&
            attachments.map((attr, index) => {
              return (
                <div key={'fileattached' + index} className="attach-wrapper">
                  <p className="attach-attached">{ attr.name }</p>
                  <img src={close} className="attach-attached--remove" onClick={remove} alt="" />
                </div>)
            })
  
        }
        <Field isOnlyFileAdd={true} component={Attach} href={undefined} className="eventInput eventUserComment"
               onError={(error) => {
                 setErrors(error);
               }}
               name="attached"
               type="file"
               fileReady={attachments}
               onChange={(fileData) => {
                 if (fileData && fileData.base64) {
                   setErrors(null);
                   const data = [...attachments, {...fileData}];
                   setAttachments(data);
                 }
               }}
        />
        {errors ? <>
          <div className="attach-attached--invalid">{ errors }</div>
        </> : '' }
      </>
    );
  }

const VideoRecorderForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, userId, isEditMode, objectsList, ppes, formValues, setDashcamData}) => {
    const [currentDoc, setCurrentDoc] = useState({ name: '' });
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';
    dispatch(change('VideoRecorderForm', 'id', userId)); // скрытое поле в форме с id юзера

    const removeAttached = () => {
        dispatch(change('VideoRecorderForm', 'attached', ''));
      }

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = objSelectOptions(omitRooms(objectsList));
        const placeholder = 'Выберите помещение';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormPpesSelect = ({input}) => {
        const isMulti = true;
        const options = ppeSelectOptions(ppes);
        const placeholder = 'Выберите типы СИЗ';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    useEffect(() => {
       if (setDashcamData) setDashcamData(formValues)
    }, [formValues])

    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="Основные данные">
                    <Field name="number" type="text" label="Номер"
                           component={InputField} className={className('number')}
                           validate={[required]}/>
                    <Field name="dashcam_type" type="text" label="Тип"
                           component={InputField} className={className('dashcam_type')}
                           disabled={true}
                           validate={[]}/>
                    <InputGroup title="Энергоблок" >
                        <Field
                            name="power_unit_info"
                            className={className('power_unit_info')}
                            component={ReduxFormObjectsSelect}
                            validate={[required]}
                        />
                    </InputGroup>
                    {/* <InputGroup title="Документ">
                        <Field name="file" component={AttachmentForm}
                            attachmentData={currentDoc}
                            removeAttached={removeAttached}
                        />
                    </InputGroup> */}
                </Legend>
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state, props) => {
    if (!state.videoRecorder.current?.id) {
        return {
            initialValues: {},
            formValues: getFormValues('VideoRecorderForm')(state) || {},
        };
    }
    return {
        initialValues: {
            ...state.videoRecorder.current,
            power_unit_info: {
                value: state.videoRecorder.current.power_unit_info.id,
                label: state.videoRecorder.current.power_unit_info.name
            }
        },
        cameraId: state.videoRecorder.current.id,
        formValues: getFormValues('VideoRecorderForm')(state) || {},
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'VideoRecorderForm',
        enableReinitialize: true,
    })(VideoRecorderForm)
)
