import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {DEFAULT_INTERVAL_HOURS, PAGE_SIZE, START_PAGE} from '../utils/constants';
import {clearEventReport, loadEventReport} from '../store/Events';
import {loadObjectsObservationList} from '../store/ObjectObservation';
import {getDateNow} from '../utils/time';
import {omitUndefinedAndNullFields} from '../utils/objects';
import Modal from '../components/Modal';
import Event from '../components/Event';
import withDataErrorIndication from '../components/withDataErrorIndication';
import NotificationsTable from '../tables/NotificationsTable';
import {loadNotificationLog} from '../store/NotificationLog';
import {cameraClearState, cameraFlatDetailedList} from "../store/Camera";
import {clearEmploeesState, loadEmploeesList} from "../store/Employees";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../components/Spinner";
import RemotePagination from "../tables/RemotePagination";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {customFilter, FILTER_TYPES, selectFilter, textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import {apiDataToNotificationObj, omitRooms, onlyRooms, roomsInObject} from "../utils/utils";
import {objectsListToOptions} from "../utils/inputs";
import {userFio} from "../utils/userUtils";
import RangeFilter from "../components/RangeFilter";
import { get_current_event } from '../store/Event';
import { useRouter } from 'react-router5';

const Notifications = ({events, params, current_event, user, notificationLog, actions, isPending, cameras, eventsTotal, employees, objects, isPendingObjects, isPendingNot}) => {
    const {loadEvents, clearEvents, clearEvent, get_current_event, loadNotificationLog, loadAllCameras, loadEmployeesList, loadObjects} = actions;

    const [page, setPage] = useState(START_PAGE);
    const [sizePerPage, setSizePerPage] = useState(PAGE_SIZE);
    const [event, setEvent] = useState(null);
    const [unitId, setUnitId] = useState(null);

    const route = useRouter()

    const [filtersList, setfiltersList] = useState(null);

    const [objectId, setObjectId] = useState(null);
    const [camId, setCamId] = useState(null);

    const defaultQuery = {
        dateBegin: getDateNow(-DEFAULT_INTERVAL_HOURS),
        dateEnd: getDateNow(),
    };

    useEffect(() => {
        loadAllCameras();
        loadEmployeesList();
        loadObjects();
        loadNotificationLog(START_PAGE, PAGE_SIZE, defaultQuery);
        // loadEvents(START_PAGE, PAGE_SIZE, defaultQuery);
    }, []);

    useEffect(() => {
        if (params?.eventId) {
            get_current_event(params?.eventId)
        }
        return () => {
            clearEvent()
        }
    },[params])

    useEffect(() => {
        if (current_event?.id) {
            setEvent(current_event)
        }
    },[current_event])

    const reloadQuery = () => {
        // if (filtersList !== null) {
        const filtersQuery = {
            ...(filtersList?.power_unit && {power_unit: filtersList.power_unit.filterVal}),
            ...(filtersList?.room && {object_id: filtersList.room.filterVal}),
            ...(filtersList?.camera_name && {cameraId: filtersList.camera_name.filterVal}),
            ...(filtersList?.date && {
                dateBegin: filtersList?.date.filterVal.from,
                dateEnd: filtersList?.date.filterVal.to
            }),
            ...(filtersList?.confirmation_status && {hasViolation: filtersList?.confirmation_status.filterVal}),
            ...(filtersList?.user_info && {surname: filtersList?.user_info.filterVal}),
        };
        loadNotificationLog(page, sizePerPage, {
            ...defaultQuery,
            ...omitUndefinedAndNullFields(filtersQuery)
        });
        // }
    }



    useEffect(() => {
        if (events && events.results) {
            const frameIds = events.results.map(event => event.id);
            loadNotificationLog(user.userId, null);
        }
    }, [events]);

    useEffect(() => {
        reloadQuery();
    }, [filtersList, sizePerPage, page]);

    if (isPendingObjects) {
        return <Spinner/>;
    }

    const reload = () => {
        reloadQuery();
    };

    const onTableChange = (type, {sizePerPage, page, filters, sortField, sortOrder}) => {
        clearEvents();
        if (type === 'filter') {
            //console.debug(filters)
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
        setfiltersList(filters);
    };

    document.title = 'Оповещения';

    const FilteredData = Object.entries(notificationLog || []).filter(item => item[1].object_observation !== null);
    const notifications = FilteredData.map((event) => apiDataToNotificationObj(event[1], objects.list));

    const columns = [{
        text: '№',
        dataField: 'id',
        headerStyle: () => ({width: '59px'})
    },{
        text: 'Блок',
        dataField: 'power_unit',
        headerClasses: 'table-block',
        filter: selectFilter({
            options: objectsListToOptions(omitRooms(objects.list)),
            placeholder: 'Все энергоблоки',
        }),
        headerStyle: () => ({width: '159px'}),
        // formatter: cell => objectsListToOptions[cell],
    }, {
        text: 'Помещение',
        dataField: 'room',
        filter: selectFilter({
            options: objectsListToOptions(unitId ?
                roomsInObject(unitId, onlyRooms(objects.list)) : onlyRooms(objects.list)
            ),
            placeholder: 'Все помещения'
        }),
        headerStyle: () => ({width: '159px'})
    }, {
        text: 'Камера',
        dataField: 'camera_name',
        headerStyle: () => ({width: '159px'}),
        // formatter: cell => objectToOption(cell, cameras),
    }, {
        text: 'Пользователь',
        dataField: 'user_info',
        headerStyle: () => ({width: '190px'}),
        filter: textFilter({
            placeholder: ' ',
        }),
        formatter: cell => userFio(cell),
    }, {
        text: 'Дата и время',
        dataField: 'date',
        headerStyle: () => ({width: '220px'}),
        headerClasses: 'table-date',
        filter: customFilter({
            type: FILTER_TYPES.DATE,
        }),
        filterRenderer: (onFilter, column) =>
            <RangeFilter
                onFilter={onFilter}
                labelFrom="с" labelTo="по"
                valueFrom={getDateNow(-DEFAULT_INTERVAL_HOURS)}
                valueTo={getDateNow()}
            />
    }, {
        text: 'Дата и время просмотра',
        dataField: 'date_sent',
        formatter: cell => <p>{cell}</p>,
    }]
        .map(col => ({
            ...col,
            editable: false,
            classes: 'px-0 py-0 table-cell',
        }));

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            route.navigate('NOTIFICATION', { eventId: row.id })
            setEvent(row);
        }
    };

    const onCloseModal = () => {
        setEvent(null);
    };

    let tableRef;
    return (
        <>
            <div className="btn-wrapper with-other-btns">
                <div className="btn-wrapper-left">
                    <button className="table-control-btn btn-reload" onClick={reload}>
                        <FontAwesomeIcon icon={faSyncAlt} size="lg" color="#919191"/>
                        Обновить
                    </button>
                </div>
            </div>
            {/*<NotificationsTable*/}
            {/*    apiEvents={notificationLog ? notificationLog : []}*/}
            {/*    // data={apiEvents ? apiEvents : []}*/}
            {/*    eventsCount={eventsTotal}*/}
            {/*    page={page}*/}
            {/*    objects={objects}*/}
            {/*    cameras={cameras}*/}
            {/*    sizePerPage={sizePerPage}*/}
            {/*    onTableChange={onTableChange}*/}
            {/*    onSelect={data => {*/}
            {/*        setEvent(data)*/}
            {/*    }}*/}
            {/*    isPending={isPendingEvents}*/}
            {/*/>*/}

            <div>
                <RemotePagination
                    data={notifications ? notifications : []}
                    page={page}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(...params) => {
                        const filters = tableRef.filterContext.currFilters;
                        onTableChange(null, {sizePerPage: params[0], page: params[1], filters});
                        // onTableChange(null, {sizePerPage: params[0], page: page, filters});
                    }}
                    onTableChange={(type, options) => {
                        if (type === 'filter') {
                            const object = options.filters.room;
                            setObjectId(object ? parseInt(object.filterVal) : null);
                            const unit = options.filters.power_unit;
                            setUnitId(unit ? parseInt(unit.filterVal) : null);
                        }
                        // onTableChange(type, options);
                        onTableChange(type, {sizePerPage: options.sizePerPage || sizePerPage, page: options.page, filters: options.filters});
                    }}
                    totalSize={eventsTotal}
                >
                    <BootstrapTable
                        rowEvents={rowEvents}
                        remote={{filter: true, sort: true}}
                        ref={el => tableRef = el}
                        keyField="id"
                        data={notifications ? notifications : []}
                        columns={columns}
                        defaultSorted={[{dataField: 'id', order: 'asc'}]}
                        filter={filterFactory()}
                        pagination={paginationFactory()}
                        noDataIndication={ () => isPendingNot ? <Spinner /> : 'Данные отсутствуют' }
                    />
                </RemotePagination>
            </div>

            {event &&
            <Modal
                title={`Нарушение № ${event.id} (Камера - ${event.camera_name})`}
                className="big_modal"
                isOpen
                onClose={() => {
                    route.navigate('NOTIFICATIONS')
                    clearEvent()
                    setEvent(null);
                }}>
                <Event
                    reload={() => reload()}
                    eventId={event.id}
                    employees={employees}
                />
            </Modal>}
        </>
    );
}

export default connect(
    state => ({
        user: state.user,
        event: state.event.event,
        events: state.events.list?.events,
        cameras: state.camera.flatList || [],
        current_event: state.event?.current_event || {},
        current_event_isPending: state.event?.current_event_isPending || false,
        objects: state.object,
        employees: state.employees.list,
        notificationLog: state.notificationLog.log?.pushes,
        eventsTotal: state.notificationLog.log?.total_pushes,
        isPendingNot: state.notificationLog.isPending,
        isPendingObjects: state.object.isListPending || state.camera.isListPending || state.thermalCamera.isListPending || state.employees.isListPending,
        // isPending: state.object.isListPending || state.camera.isListPending || state.events.isPending || state.employees.isListPending,
        isError: state.events.isError || state.notificationLog.isError || state.camera.isListError,
    }),
    dispatch => ({
        actions: {
            loadEvents: (pageNumber, pageSize, query) => {
                dispatch(clearEventReport());
                dispatch(loadEventReport(query, pageNumber, pageSize));
            },
            clearEvents: () => {
                dispatch(clearEventReport());
            },
            loadNotificationLog: (pageNumber, pageSize, query) => {
                dispatch(loadNotificationLog(query, pageNumber, pageSize))
            },
            loadObjects: () => {
                dispatch(loadObjectsObservationList());
            },
            clearEvent: () => {
                // dispatch(clearEventReport());
                dispatch({type: 'CLEAR_EVENT'});
            },
            get_current_event: (eventId) => {
                dispatch(get_current_event(eventId));
            },
            loadAllCameras: () => {
                dispatch(cameraClearState());
                dispatch(cameraFlatDetailedList());
            },
            loadEmployeesList: () => {
                dispatch(clearEmploeesState());
                dispatch(loadEmploeesList());
            },
        }
    }),
)(withDataErrorIndication(Notifications));
