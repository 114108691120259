import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker/es';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import ru from 'date-fns/locale/ru';

import {DATE_FORMAT} from '../utils/constants';

registerLocale('ru', ru);
setDefaultLocale(ru);

const RangeFilter = ({onFilter, valueFrom, valueTo, labelFrom, labelTo, defaultValue, style, onClick}) => {

    const [from, setFrom] = useState(valueFrom);
    const [to, setTo] = useState(valueTo);

    useEffect(() => {
        if(defaultValue) {
            onFilter(defaultValue)
        }
    }, [defaultValue])

    const filter = (value, name) => {
        const data = {
            from, to
        };
        data[name] = value;
        onFilter(data);
    };

    return (
        <div style={style} onClick={onClick}>
            {labelFrom && <span>{labelFrom}</span>}
            <DatePicker
                selected={from}
                dateFormat={DATE_FORMAT}
                placeholderText="с"
                onChange={(value) => {
                    setFrom(value);
                    filter(value, 'from');
                }}
            />
            {labelTo && <span>{labelTo}</span>}
            <DatePicker
                selected={to}
                todayButton="Сегодня"
                dateFormat={DATE_FORMAT}
                placeholderText="по"
                onChange={(value) => {
                    setTo(value);
                    filter(value, 'to');
                }}
            />
        </div>
    );
};

export default RangeFilter;
