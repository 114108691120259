import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {omitUndefinedAndNullFields} from '../utils/objects';
import Modal from '../components/Modal';
import {DEFAULT_INTERVAL_HOURS, PAGE_SIZE, START_PAGE} from '../utils/constants';
import withDataErrorIndication from '../components/withDataErrorIndication';
import Spinner from "../components/Spinner";
import RemotePagination from "../tables/RemotePagination";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {customFilter, FILTER_TYPES, textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import RangeFilter from "../components/RangeFilter";
import {getDateNow} from "../utils/time";
import {clearEmploeesState, loadEmploeesListMenagement, clearEmploeeState, loadEmploee, deleteEmploee} from "../store/Employees";
import EmployeeModal from "../components/EmployeeModal";
import {subdivisionClearState, subdivisionList} from "../store/Subdivision";
import ActionsBtnsBeforeTable from "../components/ActionsBtnsBeforeTable";
import {downloadFile} from "../utils/requests";
import axios from "axios";

const defaultQuery = {
    personnelNumber: "",
};

const EmployeesManagement = (props) => {
    const {
        employees, loadEmployeesList, loadAllSubdivision, employee, clearEmploee, subdivisions,
        isPendingEmployees, isPendingObjects, loadEmploee, employeesTotal, deleteEmploee
    } = props;


    const [page, setPage] = useState(START_PAGE);
    const [sizePerPage, setSizePerPage] = useState(PAGE_SIZE);
    const [filtersList, setfiltersList] = useState(null);
    const [shouldShowModal, setShowModal] = useState(false);
    // const [isEditModalMode, setEditModalMode] = useState(false)

    const [isDownloadingDocs, setIsDownloadingDocs] = useState(false);
    const [shouldShowUpload, setShouldShowUpload] = useState(false);
    const [modalText, setModalText] = useState('');
    const [uploadFile, setUploadFile] = useState(false);
    const [progress, setProgress] = useState(null);

    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        loadAllSubdivision();
        // resetSelection();
        loadEmployeesList(START_PAGE, PAGE_SIZE, defaultQuery);
    }, []);

    useEffect(() => {
        reloadQuery();
        // resetSelection();
    }, [filtersList, sizePerPage, page]);

    useEffect(() => {
        // if (employee && employee.id) {
        //     setShowModal(true);
        //     // resetSelection();
        // }
    }, [employee]);

    const reloadQuery = () => {
        // if (filtersList !== null) {
        const filtersQuery = {
            ...(filtersList?.personnel_number && {personnelNumber: filtersList.personnel_number.filterVal}),
            ...(filtersList?.surname && {surname: filtersList.surname.filterVal}),
            ...(filtersList?.subdivision && {subdivisionName: filtersList.subdivision.filterVal}),
            ...(filtersList?.position && {position: filtersList?.position.filterVal}),
        };
        loadEmployeesList(page, sizePerPage, {
            ...defaultQuery,
            ...omitUndefinedAndNullFields(filtersQuery)
        });
        // }
    };

    if (isPendingObjects) {
        return <Spinner/>;
    }

    const reload = () => {
        reloadQuery();
    };


    const onTableChange = (type, {sizePerPage, page, filters, sortField, sortOrder}) => {
        // clearEvents();
        if (type === 'filter') {
            // console.debug(filters)
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
        setfiltersList(filters);
    };

    const getEmploeeDocx = () => {
        let url = `/api/facecloud/person-storage/persons/document/docx/`;

        setIsDownloadingDocs(true);

        downloadFile(url,'emploees.zip')
            .then(() => {
                setIsDownloadingDocs(false);
            })
    };

    const onFileChange = async (e) => {
        setUploadFile(true);
        const targetFile = e.target.files[0];
        const getBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        };

        if (targetFile) {
            const formData = new FormData();
            formData.append(
                "document",
                e.target.files[0],
                e.target.files[0].name,
            );

            const headers =  {
                "Content-Type": "multipart/form-data",
                "Accept": "application/zip",
                "type": "formData"
            }
            const config = {
                headers: headers,
                onUploadProgress: e => console.log("111111111", Math.min(Math.round(e.loaded * 100 / e.total), 99))
            };
            const url = '/api/facecloud/person-storage/persons/document/docx/';
            return axios.post(url, formData, config)
                .then((res) => {
                    setModalText(res.data);
                    setShouldShowUpload(true);
                    setUploadFile(false)

                })
                .catch(err => {
                    setModalText(err);
                    console.error('error>', err);
                    setShouldShowUpload(true);
                    setUploadFile(false);
                });
        }
    };

    const resetSelection = () => {
        setSelectedRowIds([]);
        setSelectedRow(null);
    }

    const manageSelection = (row, isSelect, rowIndex, e) => {
        // эта функция нужна, чтобы можно было выделять только по одной строке
        setTimeout(() => {
            if (isSelect) {
                setSelectedRowIds([row.id]);
                setSelectedRow(row);
            } else {
                resetSelection();
            }
        }, 0);
    };

    const columns = [
        {
            text: 'Табельный номер',
            dataField: 'personnel_number',
            headerStyle: () => ({width: '150px'}),
            editable: false,
            isKey: true,
            filter: textFilter({
                placeholder: ' ',
            }),
        },{
            text: 'Фамилия',
            dataField: 'surname',
            headerStyle: () => ({width: '170px'}),
            editable: false,
            filter: textFilter({
                placeholder: ' ',
            }),
        }, {
            text: 'Имя',
            dataField: 'firstname',
            headerStyle: () => ({width: '170px'}),
            editable: false,
        }, {
            text: 'Отчество',
            dataField: 'patronymic',
            headerStyle: () => ({width: '170px'}),
            editable: false,
        }, {
            text: 'Подразделение',
            dataField: 'subdivision',
            headerStyle: () => ({width: '170px'}),
            editable: false,
            filter: textFilter({
                placeholder: ' ',
            }),
            formatter: (cell) => {
                return <p>{cell.name}</p>
            }
        }, {
            text: 'Должность',
            dataField: 'position',
            headerStyle: () => ({minWidth: '316px'}),
            editable: false,
            filter: textFilter({
                placeholder: ' ',
            }),
        }, {
            text: 'Фото',
            dataField: 'images',
            headerStyle: () => ({width: '573px'}),
            editable: false,
            formatter: (cell) => {
                // return <img className="img-employee-table" src={cell[0]?.preview}/>
                return cell.map(item => <img className="img-employee-table" alt="img-employee" src={item.preview}/>)
            }
        },
    {
        text: 'Дата и время',
        dataField: 'date',
        headerStyle: () => ({display: 'none'}),
        headerClasses: 'table-date',
        // без этого фильтра вся таблица падает, хз почему
        filter: customFilter({
            type: FILTER_TYPES.DATE,
        }),
        filterRenderer: (onFilter, column) =>
            <RangeFilter
                onFilter={onFilter}
                labelFrom="с" labelTo="по"
                valueFrom={getDateNow(-DEFAULT_INTERVAL_HOURS)}
                valueTo={getDateNow()}
            />
    }]
        .map(col => ({
            ...col,
            editable: false,
            classes: 'px-0 py-0 table-cell',
        }));

    let tableRef;

    return (
        <>
            <ActionsBtnsBeforeTable
                objects={employees ? employees : []}
                remote={{filter: true, sort: true}}
                isEditable={false}
                getEmploeeDocx={getEmploeeDocx}
                onFileChange={onFileChange}
                uploadFile={uploadFile}
                isDownloadingDocs={isDownloadingDocs}
                progress={progress}
                selectedRowIds={selectedRowIds}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setSelectedRowIds={setSelectedRowIds}

                actions={{
                    update: () => {
                    },
                    create: () => {
                        setShowModal(true);
                        // setEditModalMode(true)
                    },
                    purge: () => {
                        deleteEmploee(selectedRowIds);
                        setTimeout(()=> {
                            reload();
                        }, 600);
                    },
                    reload: reload
                }}
                rowEvents={{
                    onDoubleClick: (e, row, rowIndex) => {
                        loadEmploee(row.id);
                    }
                }}
            />
            <RemotePagination
                data={employees ? employees : []}
                totalSize={employeesTotal}
                sizePerPage={sizePerPage}
                page={page}
                onSizePerPageChange={(...params) => {
                    const filters = tableRef.filterContext.currFilters;
                    onTableChange(null, {sizePerPage: params[0], page: params[1], filters});
                    // onTableChange(null, {sizePerPage: params[0], page: page, filters});
                }}
                onTableChange={(type, options) => {
                    // onTableChange(type, options);
                    onTableChange(type, {sizePerPage: options.sizePerPage || sizePerPage, page: options.page, filters: options.filters});
                }}
            >
                <BootstrapTable
                     rowEvents={{
                            onDoubleClick: (e, row, rowIndex) => {
                                loadEmploee(row.id);
                                setShowModal(true);
                            }
                        }}
                    remote={{filter: true, sort: true}}
                    ref={el => tableRef = el}
                    keyField="id"
                    data={employees ? employees : []}
                    columns={columns}
                    defaultSorted={[{dataField: 'personnel_number', order: 'asc'}]}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                    // onTableChange={onTableChange}
                    // onTableChange={() => {}}
                    noDataIndication={() => isPendingEmployees ? <Spinner/> : 'Данные отсутствуют'}


                     selectRow={{
                         mode: 'checkbox',
                         selected: selectedRowIds,
                         hideSelectAll: true,
                         clickToSelect: true,
                         clickToEdit: true,
                         classes: 'selection-row',
                         hideSelectColumn: true,
                         onSelect: manageSelection,
                         onSelectAll: () => {
                         },
                    }}
                />
            </RemotePagination>

            {shouldShowModal &&
            <EmployeeModal
                subdivisions={subdivisions}
                reload={reload}
                onCancel={() => {
                    setShowModal(false);
                    clearEmploee();
                    // setEditModalMode(false)
                }}
                onClose={() => {
                    // setEditModalMode(false)
                    setShowModal(false);
                    clearEmploee();
                }}
                // isEditMode={isEditModalMode}
            />
            }
            {shouldShowUpload &&
            <Modal
                title={`Список работников обновлен`}
                isOpen
                closeButtonWithText={true}
                className="small_modal"
                onClose={() => {
                    setShouldShowUpload(false);
                    setModalText('');
                    reload();
                }}
            >
                {modalText &&
                <div className="modal-log">
                    <p>{modalText}</p>
                </div>
                }
            </Modal>
            }
        </>
    );
};

export default connect(
    state => ({
        employees: state.employees.list,
        employeesTotal: state.employees.persons_count,
        subdivisions: state.subdivision.list,
        isPendingEmployees: state.employees.isListPending,
        isPendingObjects: state.subdivision.isListPending,
        isError: state.events.isError,
        employee: state.employees.current,
    }),
    dispatch => ({
        loadEmployeesList: (pageNumber, pageSize, query) => {
            dispatch(clearEmploeesState());
            dispatch(loadEmploeesListMenagement(query, pageNumber, pageSize));
        },
        loadAllSubdivision: () => {
            dispatch(subdivisionClearState());
            dispatch(subdivisionList());
        },
        clearEmploee: () => {
            dispatch(clearEmploeeState());
        },
        deleteEmploee: (id) => {
            dispatch(deleteEmploee(id));
        },
        loadEmploee: (id) => {
            dispatch(clearEmploeeState());
            dispatch(loadEmploee(id));
        },
    }),
)(withDataErrorIndication(EmployeesManagement));

// import React, {useEffect, useState} from 'react';
// import {connect} from 'react-redux';
// import filterFactory, {customFilter, FILTER_TYPES, selectFilter, textFilter} from 'react-bootstrap-table2-filter';
//
// import GeneralObjectsTable from '../tables/GeneralObjectsTable';
// import withDataErrorIndication from '../components/withDataErrorIndication';
// import {clearEmploeesState, clearEmploeeState, deleteEmploee, loadEmploee, loadEmploeesList} from "../store/Employees";
// import EmployeeModal from "../components/EmployeeModal";
// import {subdivisionClearState, subdivisionList} from "../store/Subdivision";
// import {downloadFile, uploadFile, uploadFileWithBody} from "../utils/requests";
// import Spinner from "../components/Spinner";
// import EmployeeForm from "../forms/EmployeeForm";
// import RemoteSubmitButton from "../forms/RemoteSubmitButton";
// import Modal from "../components/Modal";
// import axios from "axios";
// import {DEFAULT_INTERVAL_HOURS, PAGE_SIZE, START_PAGE} from "../utils/constants";
// import {omitUndefinedAndNullFields} from "../utils/objects";
// import RemotePagination from "../tables/RemotePagination";
// import {getDateNow} from "../utils/time";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import BootstrapTable from "react-bootstrap-table-next";
// import {objectsListToOptions} from "../utils/inputs";
// import {cameraInObject, omitRooms, onlyRooms, roomsInObject} from "../utils/utils";
// import RangeFilter from "../components/RangeFilter";
//
// const defaultQuery = {
//     personnelNumber: "",
// };
//
// const EmployeesManagement = ({employees = [], employee, actions, isListPending, subdivisions, employeesTotal, isPendingObjects, isPendingEmployees}) => {
//     const {
//         loadEmployeesList,
//         loadEmploee,
//         deleteEmploee,
//         clearEmploee,
//         loadAllSubdivision
//     } = actions;
//
//     const [isDownloadingDocs, setIsDownloadingDocs] = useState(false);
//     const [shouldShowModal, setShowModal] = useState(false);
//     const [shouldShowUpload, setShouldShowUpload] = useState(false);
//     const [modalText, setModalText] = useState('');
//     const [uploadFile, setUploadFile] = useState(false);
//     const [progress, setProgress] = useState(null);
//
//     const [page, setPage] = useState(START_PAGE);
//     const [sizePerPage, setSizePerPage] = useState(PAGE_SIZE);
//     const [filtersList, setfiltersList] = useState(null);
//
//     useEffect(() => {
//         // loadEmployeesList();
//         loadAllSubdivision();
//         loadEmployeesList(START_PAGE, PAGE_SIZE, defaultQuery);
//     }, []);
//
//
//     useEffect(() => {
//         if (employee && employee.id) {
//             setShowModal(true);
//
//         }
//     }, [employee]);
//
//     const reloadQuery = () => {
//         const filtersQuery = {
//             ...(filtersList?.personnel_number && {personnelNumber: filtersList.personnel_number.filterVal}),
//             ...(filtersList?.surname && {surname: filtersList.surname.filterVal}),
//             ...(filtersList?.subdivision_name && {cameraId: filtersList.subdivision_name.filterVal}),
//             ...(filtersList?.position && {position: filtersList?.position.filterVal}),
//         };
//         loadEmployeesList(page, sizePerPage, {
//             ...defaultQuery,
//             ...omitUndefinedAndNullFields(filtersQuery)
//         });
//     }
//
//     useEffect(() => {
//         reloadQuery();
//     }, [filtersList, sizePerPage, page]);
//
//     if (isPendingObjects) {
//         return <Spinner/>;
//     }
//
//     const reload = () => {
//         reloadQuery();
//     };
//
//     const onTableChange = (type, {sizePerPage, page, filters, sortField, sortOrder}) => {
//         if (type === 'filter') {
//             // console.debug(filters)
//         } else {
//             setPage(page);
//             setSizePerPage(sizePerPage);
//         }
//         setfiltersList(filters);
//     };
//
//     const getEmploeeDocx = () => {
//         let url = `/api/facecloud/person-storage/persons/document/docx/`;
//
//         setIsDownloadingDocs(true);
//
//         downloadFile(url,'emploees.zip')
//             .then(() => {
//                 setIsDownloadingDocs(false);
//             })
//     };
//
//     const onFileChange = async (e) => {
//         setUploadFile(true);
//         const targetFile = e.target.files[0];
//         const getBase64 = (file) => {
//             return new Promise((resolve, reject) => {
//                 const reader = new FileReader();
//                 reader.readAsDataURL(file);
//                 reader.onload = () => resolve(reader.result);
//                 reader.onerror = error => reject(error);
//             });
//         };
//
//         if (targetFile) {
//             const formData = new FormData();
//             formData.append(
//                 "document",
//                 e.target.files[0],
//                 e.target.files[0].name,
//             );
//
//
//             // const fileName = targetFile.name;
//             // const ext = fileName.split('.').pop();
//             // const val = await getBase64(targetFile);
//             // const res = val.split('base64,');
//             // const contentData = res[1];
//             // const newVal = `data:application/${ext};base64,${contentData}`;
//
//             const headers =  {
//                 "Content-Type": "multipart/form-data",
//                 "Accept": "application/zip",
//                 "type": "formData"
//             }
//             const config = {
//                 headers: headers,
//                 onUploadProgress: e => console.log("111111111", Math.min(Math.round(e.loaded * 100 / e.total), 99))
//             };
//             return axios.post('/api/facecloud/person-storage/persons/document/docx/', formData,
//                 config
//             )
//                 .then((res) => {
//                     return res.data;
//                 })
//                 .catch(err => {
//                     console.error('error>', err);
//                     return err;
//                 });
//             // uploadFileWithBody("/api/facecloud/person-storage/persons/document/docx/", formData)
//             //     .then((res) => {
//             //         setModalText(res);
//             //         setShouldShowUpload(true);
//             //         setUploadFile(false)
//             //         // loadEmployeesList();
//             //     })
//         }
//     };
//
//
//     const Columns = () => [{
//         text: 'Табельный номер',
//         dataField: 'personnel_number',
//         headerStyle: () => ({width: '170px'}),
//         editable: false,
//         // filter: textFilter({
//         //     placeholder: ' ',
//         // }),
//     },{
//         text: 'Фамилия',
//         dataField: 'surname',
//         headerStyle: () => ({width: '170px'}),
//         editable: false,
//         // filter: textFilter({
//         //     placeholder: ' ',
//         // }),
//         filter: customFilter({
//             type: FILTER_TYPES.DATE,
//         }),
//         filterRenderer: (onFilter, column) =>
//             <RangeFilter
//                 onFilter={onFilter}
//                 labelFrom="с" labelTo="по"
//                 valueFrom={getDateNow(-DEFAULT_INTERVAL_HOURS)}
//                 valueTo={getDateNow()}
//             />
//     }, {
//         text: 'Имя',
//         dataField: 'firstname',
//         headerStyle: () => ({width: '170px'}),
//         editable: false,
//     }, {
//         text: 'Отчество',
//         dataField: 'patronymic',
//         headerStyle: () => ({width: '170px'}),
//         editable: false,
//     }, {
//         text: 'Подразделение',
//         dataField: 'subdivision.name',
//         headerStyle: () => ({width: '170px'}),
//         editable: false,
//         // filter: textFilter({
//         //     placeholder: ' ',
//         // }),
//     }, {
//         text: 'Должность',
//         dataField: 'position',
//         headerStyle: () => ({width: '170px'}),
//         editable: false,
//         // filter: textFilter({
//         //     placeholder: ' ',
//         // }),
//     }, {
//         text: 'Фото',
//         dataField: 'images',
//         editable: false,
//         formatter: (cell) => {
//             // return <img className="img-employee-table" src={cell[0]?.preview}/>
//             return cell.map(item => <img className="img-employee-table" alt="img-employee" src={item.preview}/>)
//         }
//     }
//     ];
//
//     document.title = 'Управление сотрудниками';
//
//     let tableRef;
//
//     return (
//         <>
//             <RemotePagination
//                 data={employees ? employees : []}
//                 // totalSize={employeesTotal}
//                 // sizePerPage={sizePerPage}
//                 // page={page}
//                 // onSizePerPageChange={(...params) => {
//                 //     const filters = tableRef.filterContext.currFilters;
//                 //     onTableChange(null, {sizePerPage: params[0], page: params[1], filters});
//                 //     // onTableChange(null, {sizePerPage: params[0], page: page, filters});
//                 // }}
//                 // onTableChange={(type, options) => {
//                 //     // onTableChange(type, options);
//                 //     onTableChange(type, {sizePerPage: options.sizePerPage || sizePerPage, page: options.page, filters: options.filters});
//                 // }}
//             >
//                 {/*<GeneralObjectsTable*/}
//                 {/*    objects={employees ? employees : []}*/}
//                 {/*    remote={{filter: true, sort: true}}*/}
//                 {/*    getColumns={Columns}*/}
//                 {/*    isPending={isListPending}*/}
//                 {/*    isEditable={false}*/}
//                 {/*    getEmploeeDocx={getEmploeeDocx}*/}
//                 {/*    onFileChange={onFileChange}*/}
//                 {/*    uploadFile={uploadFile}*/}
//                 {/*    isDownloadingDocs={isDownloadingDocs}*/}
//                 {/*    progress={progress}*/}
//                 {/*    noDataIndication={() => isPendingEmployees ? <Spinner/> : 'Данные отсутствуют'}*/}
//                 {/*    actions={{*/}
//                 {/*        update: () => {*/}
//                 {/*        },*/}
//                 {/*        create: () => {*/}
//                 {/*            setShowModal(true);*/}
//                 {/*        },*/}
//                 {/*        purge: deleteEmploee,*/}
//                 {/*        reload: reload*/}
//                 {/*    }}*/}
//                 {/*    rowEvents={{*/}
//                 {/*        onDoubleClick: (e, row, rowIndex) => {*/}
//                 {/*            loadEmploee(row.id);*/}
//                 {/*        }*/}
//                 {/*    }}*/}
//                 {/*/>*/}
//
//                 <BootstrapTable
//                     // rowEvents={{
//                     //     onDoubleClick: (e, row, rowIndex) => {
//                     //         loadEmploee(row.id);
//                     //     }
//                     // }}
//                     // remote={{filter: true, sort: true}}
//                     // ref={el => tableRef = el}
//                     keyField="id"
//                     data={employees ? employees : []}
//                     columns={Columns}
//                     // defaultSorted={[{dataField: 'personnel_number', order: 'asc'}]}
//                     filter={filterFactory()}
//                     // pagination={paginationFactory()}
//                     // // onTableChange={onTableChange}
//                     // onTableChange={() => {}}
//                     // noDataIndication={() => isPendingEmployees ? <Spinner/> : 'Данные отсутствуют'}
//                 />
//             </RemotePagination>
//             {shouldShowModal &&
//                 <EmployeeModal
//                     // subdivisions={subdivisions}
//                     onCancel={() => {
//                         setShowModal(false);
//                         clearEmploee();
//                     }}
//                     onClose={() => {
//                         setShowModal(false);
//                         clearEmploee();
//                     }}
//                 />
//             }
//             {shouldShowUpload &&
//             <Modal
//                 title={`Список работников обновлен`}
//                 isOpen
//                 closeButtonWithText={true}
//                 className="small_modal"
//                 onClose={() => {
//                     setShouldShowUpload(false);
//                     setModalText('');
//                     loadEmployeesList();
//                 }}
//             >
//                 {modalText &&
//                     <div className="modal-log">
//                         <p>{modalText}</p>
//                     </div>
//                 }
//             </Modal>
//             }
//         </>
//     )
// };
//
// export default connect(
//     state => ({
//         employees: state.employees.list,
//         employeesTotal: state.employees.list?.total,
//         subdivisions: state.subdivision.list,
//         isPendingEmployees: state.employees.isListPending,
//         isPendingObjects: state.subdivision.isListPending,
//         isError: state.employees.isListError,
//         employee: state.employees.current,
//     }),
//     dispatch => ({
//         actions: {
//             loadEmployeesList: (pageNumber, pageSize, query) => {
//                 dispatch(clearEmploeesState());
//                 dispatch(loadEmploeesList(query, pageNumber, pageSize));
//             },
//             loadEmploee: (id) => {
//                 dispatch(clearEmploeeState());
//                 dispatch(loadEmploee(id));
//             },
//             deleteEmploee: (id) => {
//                 dispatch(deleteEmploee(id));
//             },
//             clearEmploee: () => {
//                 dispatch(clearEmploeeState());
//             },
//             loadAllSubdivision: () => {
//                 dispatch(subdivisionClearState());
//                 dispatch(subdivisionList());
//             },
//         }
//     }),
// )(withDataErrorIndication(EmployeesManagement));
