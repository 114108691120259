export const getDateNow = (offsetHours = 0) => {
    const date = new Date();
    date.setSeconds(0, 0);
    const timestamp = date.getTime();
    const oneMinute = 60 * 1000;
    return new Date(timestamp + oneMinute + offsetHours * 60 * 60 * 1000);
};

export const TimestampToIntAndLocal = (timestamp) => {
    if (!timestamp) return '';
    const intDate = Math.floor(timestamp);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return new Date(intDate * 1000).toLocaleString('ru-RU', { timeZone });
};

export const getStartAndEndOfDay = () => {
    const start = new Date()
    const end = new Date()
  
    start.setHours(0, 0, 0, 0)
    end.setHours(23, 59, 59, 999)
  
    return {
      start,
      end,
    }
  }