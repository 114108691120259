import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import {change, Field, reduxForm} from 'redux-form';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup, InputSimple} from '../inputs';
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";
import {ROLE_NAMES, ROLES} from "../roles";

const required = value => value ? undefined : 'Обязательное поле';

const email = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email) ? undefined : 'Требуется корректный адрес email';
};

const password = (value, allValues) =>
    (!allValues.userId && !value) || value.length >= 12 ? undefined : 'Требуется пароль длиной не менее 12 символов';

const passwordRepeat = (value, allValues) =>
    (!allValues.userId && !value) || value === allValues.password ? undefined : 'Пароли не совпадают';

const ReduxFormRolesSelect = ({input}) => {
    const isMulti = true;
    const options = userSelectRoleOptions();
    const placeholder = 'Выберите одну или несколько ролей';
    return ReduxFormSelectValid(isMulti, options, placeholder)({input});
};

const dateFormatter = (date) => {
    if (date) {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return new Date(date * 1000).toLocaleString('ru-RU', {timeZone});
    }
};

const UserForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, userId, isEditMode, initialValues, formValues, createUser, userType, onSaveFormState}) => {
    const className = (fieldName) => forms && forms.user && !forms.user[fieldName].valid ?
        'is-invalid' : '';

    useEffect(() => {
        if (userId)
        dispatch(change('UserForm', 'id', userId)); //
    },[userId])

    useEffect(() => {
        onSaveFormState('userInfo', formValues)
    },[formValues])

    let editAD = false;

    if (userId && userType !== 0){
        editAD = true;
    }


    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="Учетные данные">
                    <InputGroup title="Полное имя">
                        <div className="full-name">
                            <Field name="surname" type="text" component={InputSimple}
                                   className={className('surname')}
                                   placeholder="Фамилия"
                                   disabled={editAD}
                                   validate={[]}/>
                            <Field name="firstname" type="text" component={InputSimple}
                                   className={className('firstname')}
                                   disabled={editAD}
                                   placeholder="Имя"
                                   validate={[]}/>
                            <Field name="patronymic" type="text" component={InputSimple}
                                   className={className('patronymic')}
                                   placeholder="Отчество"
                                   disabled={editAD}
                                   validate={[]}/>
                        </div>
                    </InputGroup>
                    <Field name="username" type="text" label="Логин"
                           component={InputField} className={className('username')}
                           disabled={editAD}
                           validate={[required]}/>
                    <div className="pass-wrapper">
                        <div className="pass">
                            <Field name="password" type="text" label="Пароль" disabled={editAD}
                                component={InputField} className={className('password')}
                                validate={createUser ? [password, required] : [password]}              
                            />
                        </div>
                        <div className="passwordRepeat">
                            <Field name="passwordRepeat" type="text" label="Повторить пароль" disabled={editAD}
                                   component={InputField} className={className('passwordRepeat')}
                                   validate={createUser ? [passwordRepeat, required] : [passwordRepeat]}
                                   />
                        </div>
                    </div>
                    {userId &&
                     <Field
                        name="blocked"
                        component={EnableCheckbox}
                        labelText="Блокирован"
                    />
                    }
                       
                </Legend>
                <Legend title="Контактные данные" className="long-title">
                    <Field name="phone_number" type="text" label="Мобильный"
                           component={InputField} className={className('mobile')}
                           disabled={editAD}
                           validate={[]}/>
                    <Field name="phone_number_additional" type="text" label="Дополнительный телефон"
                           component={InputField} className={className('phone')}
                           disabled={editAD}
                           validate={[]}/>
                    <Field name="email" type="text" label="Электронная почта"
                           component={InputField} className={className('email')}
                           disabled={editAD}
                           validate={[]}/>
                </Legend>
                <Legend title="Права пользователя на роли" className="rols-form">
                    <InputGroup title="Роли">
                        <Field
                            name="roles"
                            validate={[required]}
                            component={ReduxFormRolesSelect}
                        />
                    </InputGroup>
                </Legend>
                <Legend title="Статистика работы" className="long-title statistic">
                    <Field name="last_login_at" type="text" label="Дата последнего входа"
                           component={InputField} className={className('last_login_at')}
                           disabled/>
                    <Field name="updated_at" type="text" label="Дата модификации записи"
                           component={InputField} className={className('updated_at')}
                           disabled/>
                </Legend>
            </fieldset>
        </form>
    );
};

const userSelectRoleOptions = () => {
    return Object.entries(ROLE_NAMES).map(entry => {
        const [key, value] = entry;
        return {
            value: ROLES[key],
            label: value
        }
    })
};

const userSelectRoleActiveOptions = (roles) => roles.map(role => {
    const roleKey = Object.keys(ROLES).find(key => ROLES[key] === role);
    return {
        value: role,
        label: ROLE_NAMES[roleKey] || role
    }
});

const mapStateToProps = (state) => {
    if (!state.users.current) {
        return {};
    }
    return {
        initialValues: {
            ...state.users.current,
            last_login_at: dateFormatter(state.users.current.last_login_at),
            updated_at: dateFormatter(state.users.current.updated_at),
            roles: userSelectRoleActiveOptions(state.users.current.roles),
        },
        formValues: {
            ...state.form?.UserForm?.values ?? {},
            last_login_at: dateFormatter(state.users.current.last_login_at),
            updated_at: dateFormatter(state.users.current.updated_at),
          
        },
        userId: state.users.current.id,
        userType: state.users.current.type,
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'UserForm',
        enableReinitialize: true,
    })(UserForm)
)
