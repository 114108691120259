import axios from 'axios';
import imageUtils from "../utils/imageUtils";
import {clearEmploeesState, loadEmploeesList} from "./Employees";

const CAMERA = {
    LIST:       'CAMERA_LIST',
    FLAT_LIST:  'CAMERA_FLAT_LIST',
    LIST_PENDING: 'CAMERA_LIST_PENDING',
    LIST_ERROR: 'CAMERA_LIST_ERROR',
    DETAIL:     'CAMERA_DETAIL',

    CREATE:     'CAMERA_CREATE',
    CREATE_SUCCESS:'CAMERA_CREATE_SUCCESS',
    CREATE_ERROR: 'CAMERA_CREATE_ERROR',

    EDIT_SUCCESS: 'CAMERA_EDIT_SUCCESS',
    EDIT:       'CAMERA_EDIT',
    EDIT_ERROR: 'CAMERA_EDIT_ERROR',

    DELETE:     'CAMERA_DELETE',
    ALL_INFO:   'CAMERA_ALL_INFO',
    CLEAR:      'CAMERA_CLEAR',
    CAMERA_CLEAR:      'CAMERA_CURRENT_CLEAR',
};

const initialState = {
    current: {},
    confirm_loading: false,
    createEditError: false,
};

const cameraReducer = (state = initialState, action) => {
    switch(action.type) {
        case CAMERA.LIST:
            return {
                ...state,
                list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case CAMERA.FLAT_LIST:
            return {
                ...state,
                flatList: action.payload.filter(camera => camera.obj_id !== null),
                isListPending: false,
                isListError: false,
            };
     
        case CAMERA.CREATE:
        case CAMERA.EDIT: {
            return  {
                ...state,
                confirm_loading: true,
                current: action.payload,
                createEditError: false
            }
        }
       
        case CAMERA.CREATE_SUCCESS:
        case CAMERA.EDIT_SUCCESS:  {
            return {
                ...state,
                confirm_loading: false,
                createEditError: false,
            }
        }
        case CAMERA.CREATE_ERROR: 
        case CAMERA.EDIT_ERROR: {
            return {
                ...state,
                confirm_loading: false,
                createEditError: true,
            }
        }
        case CAMERA.DETAIL:
        case CAMERA.DELETE:
        case CAMERA.ALL_INFO:
            return {
                ...state,
                current: {...state?.current ?? {}, ...action.payload},
                confirm_loading: action.payload?.confirm_loading ?? false
            };
        case CAMERA.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case CAMERA.LIST_ERROR:
            return {
                isListError: true,
            }
        case CAMERA.CLEAR:
            return initialState;
        case CAMERA.CAMERA_CLEAR:
            return {
                ...state,
                current: {}
            };
        default:
            return state;
    }
};

export default cameraReducer;

export const cameraList = (with_categories= false) => dispatch => {
    dispatch({
        type: CAMERA.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/camera-storage/optical-cameras/?with_categories=${with_categories}`)
        .then(res => {
            dispatch({
                type: CAMERA.LIST,
                payload: res.data
            });
        })
};

export const cameraFlatDetailedList = () => dispatch => {
    dispatch({
        type: CAMERA.LIST_PENDING,
        payload: null,
    });
    const getCamerasIds = (cameras) => {
        let ids = [];
        for (const camera of cameras) {
            ids.push(camera.id)
        }
        return ids;
    };
    const setCamerasImgs = (cameras, camera_img) => {
        for (const cam of cameras) {
            cam.screenshot_preview = camera_img;
        }
    };


    axios.get('/api/statistics/camera-storage/optical-cameras/')
        .then(res => {
            let cameras = res.data;
            cameras.sort((a, b) => (a.id > b.id) ? 1 : -1);
            setCamerasImgs(cameras, cameras.preview); // присваиваю картинки на камеры
            dispatch({
                type: CAMERA.FLAT_LIST,
                payload: cameras
            });
        })
        .catch(err => {
            dispatch({
                type: CAMERA.LIST_ERROR,
                payload: err,
            });
        })
};

export const cameraLoad = (objId, cameraId) => dispatch => {
    dispatch({
        type: CAMERA.LIST_PENDING,
        payload: null,
    });
    // dispatch(cameraCurrentClearState());
    axios.get(`/api/statistics/camera-storage/${objId}/optical-cameras/${cameraId}/`)
        .then(res => {
            dispatch({
                type: CAMERA.DETAIL,
                payload: {
                    ...res.data,
                    // screenshot: {
                    //     img: res.data.image,
                    //     sizes: await imageUtils.imgSize(res.data.image)
                    // }
                },
            });
        });
};

export const dashcamLoad = (cameraId) => dispatch => {
    dispatch({
        type: CAMERA.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/kola-dashcam/dashcams/${cameraId}/`)
        .then(res => {
            dispatch({
                type: CAMERA.DETAIL,
                payload: {
                    ...res.data,
                    isDashcam: true,
                },
            });
        });
};

/* Создание новой камеры */
export const cameraCreate = (objId, data, userFormValues, onClose) => dispatch => {
    dispatch({
        type: CAMERA.CREATE,
        payload: userFormValues,
    });

    dispatch(cameraFlatDetailedList());
    // axios.post(`/api/statistics/kola-backend/objects/cameras/`, data)
    axios.post(`/api/statistics/kola-backend/objects/${objId}/optical-cameras/`, data)
        .then(res => {
            dispatch({
                type: CAMERA.CREATE_SUCCESS,
                payload: {isReloadList: false, confirm_loading: false},
            });
            dispatch(cameraFlatDetailedList());
           
        })
        .catch(err => {
            console.log('error')
            dispatch({
                type: CAMERA.CREATE_ERROR,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString(),
                    confirm_loading: false
                },
            });
        }).then(() => onClose())
};

export const cameraEdit = (objId, cameraId, data,userFormValues, onClose) => dispatch => {
    dispatch({
        type: CAMERA.EDIT,
        payload: userFormValues,
    });
    axios.put(`/api/statistics/kola-backend/objects/${objId}/optical-cameras/${cameraId}/`, data)
        .then(res => {
            dispatch({
                type: CAMERA.EDIT_SUCCESS,
                payload: {isReloadList: false, confirm_loading: false},
            });
            dispatch(cameraClearState());
            dispatch(cameraFlatDetailedList());
        }).then(() => onClose())
        .catch(err => {
            dispatch({
                type: CAMERA.EDIT_ERROR,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString(),
                    confirm_loading: false
                },
            });
        })
};

export const dashcamEdit = (camId, data, onClose) => dispatch => {
    axios.put(`/api/statistics/kola-dashcam/dashcams/${camId}/`, data)
        .then(res => {
            dispatch({
                type: CAMERA.EDIT_SUCCESS,
                payload: {isReloadList: false, confirm_loading: false},
            });
            dispatch(cameraClearState());
            dispatch(cameraFlatDetailedList());
        }).then(() => onClose())
        .catch(err => {
            dispatch({
                type: CAMERA.EDIT_ERROR,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString(),
                    confirm_loading: false
                },
            });
        })
};

export const cameraDelete = (objId, cameraId) => dispatch => {
    axios.delete(`/api/statistics/kola-backend/objects/${objId}/optical-cameras/${cameraId}/`)
        .then(res => {
            let data = {isReloadList: false};
            dispatch({
                type: CAMERA.DELETE,
                payload: data,
            });
            dispatch(cameraClearState());
            dispatch(cameraFlatDetailedList());
        });
};

export const cameraClearState = () => dispatch => {
    dispatch({
        type: CAMERA.CLEAR,
        payload: null,
    });
};

export const cameraCurrentClearState = () => dispatch => {
    dispatch({
        type: CAMERA.CAMERA_CLEAR,
        payload: null,
    });
};



export const cameraFullInformationLoad = (objId, camId) => dispatch => {
    axios.get(`/api/video/object-observations/${objId}/optical-cameras/${camId}/`)
        .then(res => {
            dispatch({
                type: CAMERA.ALL_INFO,
                payload: res.data,
            });
        })
};