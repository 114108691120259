import React, {cloneElement, useEffect} from 'react';
import MaterialIcon from './MaterialIcon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import {faClone} from "@fortawesome/free-regular-svg-icons";

const Modal = ({children, title, isOpen, showEditButton = false, showCloseButton = true, showPrintButton = false,
                   closeButtonWithText = false, onClose, onEdit, className}) => {

    useEffect(() => {
        const close = (e) => {
            if(e.keyCode === 27){
                onClose();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    },[]);

    const onPrint = (e) => {
        e.preventDefault();
        
        const bodyElement = document.getElementsByTagName('body')[0];
    
        bodyElement.classList.add('printed_content');

        window.print();

        bodyElement.classList.remove('printed_content');
    };

    if (!isOpen) {
        return null;
    }


    return (
        <div className={className ? 'modal-wrapper ' + className : 'modal-wrapper'}>
            <div className="modal section-to-print">
                <div className="modal-header">
                    <div className="modal-title">{title}</div>
                    <div className="btn-wrapper">
                        {showPrintButton &&
                        <button onClick={onPrint}>
                            <FontAwesomeIcon icon={faClone}/>
                            {showPrintButton && <span className="button-text">Печать</span>}
                        </button>}
                        {showEditButton &&
                        <button onClick={onEdit}>
                            <FontAwesomeIcon icon={faEdit}/>
                            <span className="button-text">Редактировать</span>
                        </button>}
                        {showCloseButton &&
                        <button onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes}/>
                            {closeButtonWithText && <span className="button-text">Закрыть</span>}
                        </button>}
                    </div>
                </div>
                <div className="modal-content">
                    {cloneElement(children, {
                        /* Тут можно будет передавать в контент данные из Modal */
                        closeModal: onClose,
                    })}
                </div>
            </div>
        </div>
    )
}

export default Modal;
