import React from 'react';

import UserForm from '../forms/UserForm';
import Modal from './Modal';
import TabsWithContent from './TabsWithContent';
import UserSettingsForm from '../forms/UserSettingsForm';
import UserRolesForm from '../forms/UserRolesForm';
import RemoteSubmitButton from '../forms/RemoteSubmitButton';
import {connect} from 'react-redux';
import {createUser, saveUser, saveUserAccesses, saveUserPermissions} from '../store/Users';
import {SETTINGS} from '../utils/constants';
import Spinner from './Spinner';
import {cameraList} from '../store/Camera';
import {loadObjectsObservationList} from "../store/ObjectObservation";
import {clearPPEs, loadPPEs} from "../store/PPEs";
import UserAccessesForm from "../forms/UserAccessesForm";
import {createEmploee, saveEmploee} from "../store/Employees";
import EmployeeForm from "../forms/EmployeeForm";

const dataToType = (data) => {
    return {
        ...data,
    };
};

class EmployeeModal extends React.Component {
    state = {
        isEditMode: false
        // isEditMode: this.props.isEditMode,
    };

    userFormValues = {};

    onEditForm() {
        this.setState({isEditMode: true})
    };


    render() {
        const {onClose, onCancel, isPending, cameras, employeeId, subdivisions, reload} = this.props;
        const {createEmployee, saveEmployee} = this.props.actions;

        return (
            <Modal
                title={`Работник `}
                isOpen
                showEditButton={!this.state.isEditMode}
                closeButtonWithText={true}
                onClose={onClose}
                onEdit={() => this.onEditForm()}
                className="user-modal"
            >{isPending ?
                <Spinner/> :
                <>
                    <>
                        <EmployeeForm
                            handleCancel={onCancel}
                            onSubmit={values => {
                                this.userFormValues = { ...values};
                                delete this.userFormValues.undefined;
                                this.userFormValues.subdivision_id = this.userFormValues.subdivision.value;
                                if (employeeId) {
                                    saveEmployee(employeeId, this.userFormValues);
                                    setTimeout(()=> {
                                        reload();
                                    }, 600);
                                } else {
                                    createEmployee(this.userFormValues);
                                    setTimeout(()=> {
                                        reload();
                                    }, 600);
                                }
                                onClose();

                            }}
                            isEditMode={this.state.isEditMode}
                            subdivisions={subdivisions}
                        />
                        <div className="submit-wrapper">
                            {this.state.isEditMode &&
                            <RemoteSubmitButton
                                forms={['EmployeeForm']}
                                className=""
                            >
                                Сохранить
                            </RemoteSubmitButton>}
                        </div>
                    </>
                </>
            }</Modal>
        )
    }
}

export default connect(
    state => ({
        isPending: state.employees.isUserPending,
        employeeId: state.employees.current?.id,
    }),
    dispatch => ({
        actions: {
            saveEmployee: (id, values) => {
                const {passwordRepeat, ...rest} = values;
                dispatch(saveEmploee(id, rest));
            },
            createEmployee: (values) => {
                const {passwordRepeat, ...rest} = values;
                dispatch(createEmploee(rest));
            },
        }
    })
)(EmployeeModal);
