import React, { useEffect, useLayoutEffect, useState } from "react";

import UserForm from "../forms/UserForm";
import Modal from "./Modal";
import TabsWithContent from "./TabsWithContent";
import UserSettingsForm from "../forms/UserSettingsForm";
import UserRolesForm from "../forms/UserRolesForm";
import RemoteSubmitButton from "../forms/RemoteSubmitButton";
import { connect } from "react-redux";
import {
  createUser,
  saveUser,
  saveUserAccesses,
  saveUserPermissions,
  updateUser,
} from "../store/Users";
import { SETTINGS } from "../utils/constants";
import Spinner from "./Spinner";
import { cameraList } from "../store/Camera";
import { loadObjectsObservationList } from "../store/ObjectObservation";
import { clearPPEs, loadPPEs } from "../store/PPEs";
import UserAccessesForm from "../forms/UserAccessesForm";
import { thermalCameraList } from "../store/ThermalCamera";
import { userFio } from "../utils/userUtils";

const processNotificationSettingsValues = (values, userId) => {
  const cameras = [];
  const objectObservations = [];

  if (values.object_observations) {
    for (const obj of values.object_observations) {
      if (obj.id && obj.id.value) {
        objectObservations.push(obj);
      }
    }
  }
  if (values.cameras) {
    for (const obj of values.cameras) {
      if (obj.id && obj.id.value) {
        cameras.push(obj);
      }
    }
  }

  const data = {
    id: userId,
    cameras: cameras.map((value) => ({ ...value, id: value.id.value })),
    object_observations: objectObservations.map((value) => ({
      ...value,
      id: value.id.value,
    })),
    email: values.email,
    is_notify: values.isActive,
    send_type: values.sendType.active.value,
    notification_format: values.notificationFormat.active.value,
  };

  if (values.sendType.active.value === SETTINGS.NOTIFICATIONS_INTERVAL) {
    data.send_interval = parseInt(values.sendInterval, 10);
  }
  if (values.sendType.active.value === SETTINGS.NOTIFICATIONS_TIMETABLE) {
    data.send_at = values.sendAt
      .map((value) => value.time)
      .filter((value) => value !== undefined);
  }

  return data;
};

const permissionsSetValues = (values, cameras) => {
  const data = [];

  for (const value of values.permissionsList) {
    const parseValue = value.split("-");
    if (parseValue.length > 3) {
      const camId = parseValue[2];
      const cameraValue = cameras.filter((obj) => obj.id === parseInt(camId));
      data.push({
        id: cameraValue[0].id,
        is_notification_enabled: true,
        violation_categories: [parseValue[3]],
      });
    }
  }
  return data;
};

const accessesSetValues = (values, cameras) => {
  const data = {
    cameras: [],
    power_units: [],
    objects: [],
  };

  for (const value of values.accecess) {
    const parseValue = value.split("-");
    if (parseValue.length === 3) {
      const camId = parseValue[2];
      const cameraValue = cameras.filter((obj) => obj.id === parseInt(camId));
      data.cameras.push({
        id: cameraValue[0].id,
        is_accessed: true,
      });
    }
    if (parseValue.length === 2) {
      const ooId = parseValue[1];
      data.objects.push({
        id: parseInt(ooId),
        is_accessed: true,
      });
    }
    if (parseValue.length === 1) {
      const blockId = parseValue[0];
      data.power_units.push({
        id: parseInt(blockId),
        is_accessed: true,
      });
    }
  }
  return data;
};

const UserModal = (props) => {
  const [state, setState] = useState({
    isEditMode: props?.isEditMode,
    userInfo: {},
    access: [],
    alarm: {},
  });
  const {
    onClose,
    onCancel,
    isPending,
    cameras,
    thermalCameras,
    userId,
    user,
  } = props;
  const { saveUser, saveSettings, createUser, savePermissions, saveAccesses, updateUserInfo } =
    props.actions;
  const userFormValues = {};

  useLayoutEffect(() => {
    props.actions.loadCamerasData();
    props.actions.loadThermalCamerasData();
    props.actions.loadObjects();
  }, []);

  const onEditForm = () => {
    setState((state) => ({ ...state, isEditMode: true }));
  };

  const onSaveFormState = (name, value) => {
    setState((state) => ({ ...state, [name]: { ...value } }));
  };
  const handleEditUser = () => {
    //1 форма
    const userInfo = {...state.userInfo} ;
    userInfo.roles = userInfo.roles.map((el) => el.value)
    //2 форма
    const access = accessesSetValues(state.access, [
        ...cameras,
        ...thermalCameras,
      ]);
    //3 форма alarm
    const data = permissionsSetValues(state.alarm, [
        ...cameras,
        ...thermalCameras,
      ]);

      let resultData = [];
      const isExists = (c) => {
        for (const r of resultData) {
          if (r.id === c.id) {
            return true;
          }
        }
        return false;
      };
      const getItem = (c) => {
        for (const r in resultData) {
          if (r.id === c.id) {
            return r;
          }
        }
        return null;
      };

      for (const d of data) {
        if (d["violation_categories"].length) {
          if (!isExists(d)) {
            resultData.push(d);
          } else {
            for (const item of resultData) {
              if (item.id === d.id) {
                item["violation_categories"].push(
                  d["violation_categories"][0]
                );
              }
            }
          }
        }
      }
      resultData = {
        permissions: [...resultData],
        is_periodic_reports_enable:
          state.alarm.is_periodic_reports_enable,
      };
      updateUserInfo(userId, userInfo, access, resultData, () => onClose())
    }
  

  return (
    <Modal
      title={userId ? `Пользователь ${userFio(user)}` : `Новый пользователь`}
      isOpen
      showEditButton={!state.isEditMode}
      closeButtonWithText={true}
      onClose={onClose}
      onEdit={() => onEditForm()}
      className="user-modal"
    >
      {isPending ? (
        <Spinner />
      ) : (
        <>
          {userId ? (
            <>
              <TabsWithContent
                titles={["Данные", "Доступы", "Оповещения"]}
                tabs={[
                  <>
                    <UserForm
                      handleCancel={onCancel}
                      createUser={false}
                      onSubmit={(values) => {
                        const userFormValues = {...values} ;
                        userFormValues.roles = userFormValues.roles.map((el) => el.value)


                        if (userFormValues.id) {
                            handleEditUser()
                        } else {
                          createUser(userFormValues);
                        }
                        // onClose();
                      }}
                      isEditMode={state.isEditMode}
                      onSaveFormState={onSaveFormState}
                    />
                    <div className="submit-wrapper">
                      {state.isEditMode && (
                        <RemoteSubmitButton forms={["UserForm"]} className="">
                          Сохранить
                        </RemoteSubmitButton>
                      )}
                    </div>
                  </>,
                  <>
                    <UserAccessesForm
                      handleCancel={onCancel}
                      onSaveFormState={onSaveFormState}
                      onSubmit={(values) => {
                        handleEditUser()
                      }}
                      isEditMode={state.isEditMode}
                    />
                    <div className="submit-wrapper">
                      {state.isEditMode && (
                        <RemoteSubmitButton
                          forms={["UserAccessesForm"]}
                          className=""
                        >
                          Сохранить
                        </RemoteSubmitButton>
                      )}
                    </div>
                  </>,
                  <>
                    <UserSettingsForm
                      handleCancel={onCancel}
                      onSaveFormState={onSaveFormState}
                      onSubmit={(values) => {
                        handleEditUser()
                      }}
                      isEditMode={state.isEditMode}
                    />
                    <div className="submit-wrapper">
                      {state.isEditMode && (
                        <RemoteSubmitButton
                          forms={["UserSettingsForm"]}
                          className=""
                        >
                          Сохранить
                        </RemoteSubmitButton>
                      )}
                    </div>
                  </>,
                ]}
              />
            </>
          ) : (
            <>
              <TabsWithContent
                titles={["Данные"]}
                tabs={[
                  <UserForm
                    handleCancel={onCancel}
                    createUser={true}
                    onSaveFormState={onSaveFormState}
                    onSubmit={(values) => {
                      const userFormValues = {...values};
                      userFormValues.roles = userFormValues.roles.map((el) => el.value)
                    
                      if (userFormValues.id) {
                        handleEditUser()
                      } else {
                        createUser(userFormValues);
                      }
                      onClose();
                    }}
                    isEditMode={state.isEditMode}
                  />,
                ]}
              />
              <div className="submit-wrapper">
                {state.isEditMode && (
                  <RemoteSubmitButton forms={["UserForm"]} className="">
                    Сохранить
                  </RemoteSubmitButton>
                )}
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default connect(
  (state) => ({
    isPending:
      state.users.isUserPending ||
      state.users.isListPending ||
      state.thermalCamera.isListPending ||
      !state.camera.list ||
      state.camera.isListPending ||
      state.object.isListPending ||
      state.ppes.isPending,
    userId: state.users.current?.id,
    cameras: state.camera.list || [],
    user: state.users.current,
    thermalCameras: state.thermalCamera.list || [],
  }),
  (dispatch) => ({
    actions: {
      saveUser: (id, values) => {
        const { passwordRepeat, ...rest } = values;
        dispatch(saveUser(id, rest));
      },
      saveSettings: (id, values) => {
        // dispatch(saveUserNotificationSettings(id, values))
      },
      createUser: (values) => {
        const { passwordRepeat, ...rest } = values;
        dispatch(createUser(rest));
      },
      savePermissions: (id, data) => {
        dispatch(saveUserPermissions(id, data));
      },
      saveAccesses: (id, data) => {
        dispatch(saveUserAccesses(id, data));
      },
      loadObjects: () => {
        dispatch(loadObjectsObservationList());
      },
      loadPPEs: () => {
        dispatch(loadPPEs());
      },
      loadCamerasData: () => {
        dispatch(cameraList());
      },
      loadThermalCamerasData: () => {
        dispatch(thermalCameraList());
      },
      updateUserInfo: (id, info,permissions,accecess, onClose) => {
        dispatch(updateUser(id, info,permissions,accecess, onClose))
      }
    },
  })
)(UserModal);
