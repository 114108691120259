import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup} from '../inputs';
import {splitCameraUri, isBlock, rtspCredentials} from "../utils/utils";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";

const required = value => value ? undefined : 'Обязательное поле';

const ReduxFormProtocolSelect = ({input}) => {
    const isMulti = false;
    const options = protocolSelectOptions(['rtsp://', 'file:///', 'https://', 'http://']);
    const placeholder = 'Выберите протокол';
    return ReduxFormSelectValid(isMulti, options, placeholder)({input});
};

const protocolSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry,
            label: entry
        }
    })
};

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.name ? `${entry.name} (${entry.power_unit.name})` : entry.ru_name
        }
    })
};

const ppeSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.name ? entry.name : entry.ru_name
        }
    })
};

const protocolSelectActiveOptions = (protocol) => {
    return {
        value: protocol,
        label: protocol
    }
};

const objectsSelectActiveOptions = (objects, obj_id) => objects.map(object => {
    const activeObj = objects.find(item => item.id === obj_id);
    return {
        value: activeObj.id,
        label: `${activeObj.name} (${activeObj.power_unit.name})`
    }
});

const CameraForm = ({handleSubmit, forms, dispatch, userId, isEditMode, objectsList, ppes, formValues, setUserFormValues, isEditModal}) => {
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';
    dispatch(change('UserForm', 'id', userId)); // скрытое поле в форме с id юзера

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = objSelectOptions(isBlock(objectsList));
        const placeholder = 'Выберите помещение';
        const disabled = formValues?.isDashcam

        return ReduxFormSelectValid(isMulti, options, placeholder, disabled)({input});
    };

    useEffect(() => {
        const newFormValues = {...formValues}
        try {
            if (newFormValues?.object?.length) {
                newFormValues.object = formValues.object?.[0]
                setUserFormValues(newFormValues)
                return
            }
            setUserFormValues(formValues)
            return
        } catch (error) {
            setUserFormValues(formValues)
        }
    },[formValues])

    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="Основные данные">
                    <Field name={'name'} type="text" label="Название"
                           component={InputField} className={className('name')}
                           validate={[required]}/>
                    <InputGroup title="Помещение" >
                        <Field
                            name="object"
                            className={className('object')}
                            component={ReduxFormObjectsSelect}
                            validate={[required]}
                        />
                    </InputGroup>
                </Legend>
                {!formValues?.isDashcam && <Legend title="Параметры" >
                    <InputGroup title="Протокол">
                        <Field
                            name="protocol"
                            className={className('protocol')}
                            component={ReduxFormProtocolSelect}
                            validate={[required]}
                        />
                    </InputGroup>
                    {formValues?.protocol?.value === "rtsp://" &&
                        <Field name="credentials" type="text" label="Логин и пароль"
                               component={InputField} className={className('credentials')}
                               />
                    }
                    <Field name="host" type="text" label="Хост"
                           component={InputField} className={className('host')}
                         />
                    <Field name="suffix" type="text" label="Суффикс"
                           component={InputField} className={className('suffix')}
                           />
                    <Field
                        name="is_enabled"
                        component={EnableCheckbox}
                        labelText="Вкл"
                    />
                </Legend>}
                {/*<Legend title="События">*/}
                {/*    <InputGroup title="Нарушения">*/}
                {/*        <Field*/}
                {/*            name="violation_categories"*/}
                {/*            component={ReduxFormPpesSelect}*/}
                {/*        />*/}
                {/*    </InputGroup>*/}
                {/*</Legend>*/}
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state, props) => {
    if (state.camera.current?.isDashcam) {
       return {
            initialValues: {
                ...state.camera.current,
                name: state.camera.current?.number,
                object: {value: state.camera.current?.power_unit_info?.id, label: `Видеорегистратор (${state.camera.current?.power_unit_info?.name})`} 
            },
            formValues: getFormValues('CameraForm')(state) || {},
            cameraId: state.camera.current.id,
        }
    }

    if (!state.camera.current?.id) {
        return {
            initialValues: {},
            formValues: getFormValues('CameraForm')(state) || {},
        };
    }
    return {
        initialValues: {
            ...state.camera.current,
            ...splitCameraUri(state.camera.current.media_uri),
            protocol: protocolSelectActiveOptions(splitCameraUri(state.camera.current.media_uri).protocol),
            object: objectsSelectActiveOptions(isBlock(state.object.list ? state.object.list : []), state.camera.current.obj_id),
            credentials: rtspCredentials(state.camera.current.media_uri)[0] ? `${rtspCredentials(state.camera.current.media_uri)[0]}:${rtspCredentials(state.camera.current.media_uri)[1]}` : ''
        },
        cameraId: state.camera.current.id,
        formValues: getFormValues('CameraForm')(state) || {},
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'CameraForm',
        enableReinitialize: true,
    })(CameraForm)
)
