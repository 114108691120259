import React, {useEffect} from 'react';
import Select from 'react-select';

const CustomSelect = ({onFilter, defaultValue, ...props}) => {

    useEffect(() => {
        if(defaultValue) {
            onFilter(defaultValue)
        }
    }, [defaultValue])

    return (
        <Select
            onChange={val => {
                onFilter(val)
            }}
            defaultValue={defaultValue}
            {...props}
    />
    );
};

export default CustomSelect;
