import React, {useState, useEffect} from 'react';
import {Field, reduxForm} from 'redux-form';
import axios from 'axios';

import {DatePickerInput, objectsListToOptions, ReduxFormSelectValid, selectOptions} from '../utils/inputs';
import {onlyRooms} from '../utils/utils';
import {downloadFile} from '../utils/requests';
import {connect} from 'react-redux';
import {getDateNow} from '../utils/time';
import {DEFAULT_INTERVAL_HOURS} from '../utils/constants';
import SpinnerSmall from "../components/SpinnerSmall";
import {dateBeginTime, dateEndTime} from "../pages/EventsReport";

const timestamp = date => new Date(date).getTime() / 1000;

const EventsReportForm = (props) => {
    const {objects, handleSubmit, pristine, reset, submitting, ppeList} = props;
    const [dateFrom, setDateFrom] = useState(timestamp(dateBeginTime(getDateNow(-DEFAULT_INTERVAL_HOURS))));
    const [dateTo, setDateTo] = useState(timestamp(dateEndTime(getDateNow())));
    const [objectObservationId, setObjectObservationId] = useState('');
    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
    const [isDownloadingXlsx, setDownloadingXlsx] = useState(false);

    const [ppeArray, setPpeArray] = useState([{value: "0", label: "Все"}]);

    const getReportPdf = () => {
        let pageNumber = 1;
        let url = `/api/statistics/reports/periodic/pdf/?start_date=${dateFrom}&end_date=${dateTo}`;

        if (ppeArray){
            ppeArray.map(ppe => {
                if (ppe.value !== "0") {
                    url += `&secondary_categories=${ppe.value}`
                }
            });
        } else {
            return;
        }

        setIsDownloadingPdf(true);

        downloadFile(url,'report.pdf')
            .finally(() => {
                setIsDownloadingPdf(false);
            })
    };

    const getReportXlsx = () => {
        let url = `api/statistics/reports/xlsx/?start_date=${dateFrom}&end_date=${dateTo}`

        if (ppeArray){
            ppeArray.map(ppe => {
                if (ppe.value !== "0") {
                    url += `&secondary_categories=${ppe.value}`
                }
            });
        }
        
        setDownloadingXlsx(true)

        downloadFile(url,'report.xlsx')
        .finally(() => {
            setDownloadingXlsx(false);
        })
    }

    const changeDateFrom = (date) => {
        setDateFrom(timestamp(dateBeginTime(date)))
      // setDateFrom(date.toISOString());
    };

    const changeDateTo = (date) => {
        setDateTo(timestamp(dateEndTime(date)))
      // setDateTo(date.toISOString());
    };

    const addEmptyValue = (arr) => {
        if (arr.find((i) => i.name === "0") === undefined) {
            arr.unshift({
                name: "0",
                ru_name: "Все"
            });
        }
        return arr;
    };

    const ppeOptions = (ppe) => {
        return ppe.map(ppe => {
            return {
                value: ppe.name,
                label: ppe.ru_name
            }
        })
    };

    const ReduxFormPpeSelect = ({input}) => {
        const isMulti = true;
        const options = ppeOptions(addEmptyValue(ppeList));
        const placeholder = 'Выберите типы СИЗ';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };


    return (
        <form onSubmit={handleSubmit} className="">
            <div className="form-group">
                <div className="title">
                    <p>Период дат:</p>
                </div>
                <div className="input dates">
                    <Field name="from" component={DatePickerInput} onChange={changeDateFrom}
                           placeholder="введите дату начала"/>
                    <Field name="to" component={DatePickerInput} onChange={changeDateTo}
                           placeholder="введите дату окончания"/>
                </div>
            </div>
            <div className="form-group select-multi">
                <div className="title">
                    <p>Типы СИЗ:</p>
                </div>
                <div className="input">
                    <Field
                        name="ppeList"
                        component={ReduxFormPpeSelect}
                        onChange={(e) => setPpeArray(e)}
                    />
                </div>
            </div>
            <div className="btn-wrapper report-wrapper-btn">
                <button onClick={getReportPdf}>
                    Сформировать PDF
                </button>
                <button onClick={getReportXlsx}>
                    Сформировать XLSX
                </button>
                {(isDownloadingPdf || isDownloadingXlsx) &&
                    <div className="spinner-small-wrapper">
                        <SpinnerSmall/>
                    </div>
                }
            </div>
        </form>
    )
}

const mapStateToProps = (state) => ({
    objects: state.object.list,
    ppeList: state.regulatoryDocsSecondary.list,
    initialValues: {
        ppeList: {value: "0", label: "Все"},
        from: getDateNow(-DEFAULT_INTERVAL_HOURS),
        to: getDateNow(),
    }
})

export default connect(mapStateToProps)(
    reduxForm({form: 'EventsReportForm', enableReinitialize: false})(EventsReportForm)
);
